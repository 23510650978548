var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"header_common_box"},[_c('v-header',{attrs:{"params":{ title: _vm.title, leftIcon: true}}})],1),_vm._m(0),_c('p',[_vm._v("常见问题")]),_c('div',{staticClass:"questionList"},[_c('div',{staticClass:"question"},[_c('p',[_vm._v("1、太阳可以做什么？")]),_c('div',{staticClass:"arrowDiv",on:{"click":function($event){return _vm.arrow(0)}}},[_c('span',{staticClass:"arrow"})]),_vm._m(1)]),_c('div',{staticClass:"question"},[_c('p',[_vm._v("2、如何获得太阳")]),_c('div',{staticClass:"arrowDiv",on:{"click":function($event){return _vm.arrow(1)}}},[_c('span',{staticClass:"arrow"})]),_vm._m(2)]),_c('div',{staticClass:"question"},[_c('p',[_vm._v("3、太阳可以兑换现金吗？")]),_c('div',{staticClass:"arrowDiv",on:{"click":function($event){return _vm.arrow(2)}}},[_c('span',{staticClass:"arrow"})]),_vm._m(3)]),_c('div',{staticClass:"question"},[_c('p',[_vm._v("4、太阳可以转给好友吗？")]),_c('div',{staticClass:"arrowDiv",on:{"click":function($event){return _vm.arrow(3)}}},[_c('span',{staticClass:"arrow"})]),_vm._m(4)]),_c('div',{staticClass:"question"},[_c('p',[_vm._v("5、太阳可以退货吗？")]),_c('div',{staticClass:"arrowDiv",on:{"click":function($event){return _vm.arrow(4)}}},[_c('span',{staticClass:"arrow"})]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('p',[_vm._v("“太阳”可以用于购买到平台内所有包月服务(会员)、用于订阅信息，打赏彩友等。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer"},[_c('p',[_vm._v("1、可以购买平台VIP功能")]),_c('p',[_vm._v("2、可以订阅大师、达人的信息")]),_c('p',[_vm._v("3、可以打赏彩友")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer"},[_c('p',[_vm._v("有三种方式可以获得太阳：")]),_c('p',[_vm._v("1、充值")]),_c('p',[_vm._v("太阳充值完全属于自愿原则，可以通过微信、支付宝、银联等第三方支付平台充值获取太阳，1太阳=1元；")]),_c('p',[_vm._v("2、参加活动")]),_c('p',[_vm._v("只要每期都发表预测帖，就自动参加了《每期评优》、《周期奖励》、《月榜奖励》活动，开奖后，会根据发帖的数据自动评优，若被入选，即可自动获得丰厚的太阳奖励(会自动发送系统信息通知您)，若需了解详情，点击"),_c('span',[_vm._v("了解详情")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer"},[_c('p',[_vm._v("太阳不可以兑换现金")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer"},[_c('p',[_vm._v("“太阳”不能进行转账交易，不能兑换万恒云公司体系外的产品和服务。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer"},[_c('p',[_vm._v("鉴于好劲抽七星彩提供服务或商品的性质特殊，因此，使用太阳购买的服务或商品属于不宜退货的商品，不适用无理由退货规定。")])])
}]

export { render, staticRenderFns }