<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="content">
            <p>“太阳”可以用于购买到平台内所有包月服务(会员)、用于订阅信息，打赏彩友等。</p>
        </div>
        <p>常见问题</p>
        <div class="questionList">
            <div class="question">
                <p>1、太阳可以做什么？</p>
                <div class="arrowDiv" @click="arrow(0)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>1、可以购买平台VIP功能</p>
                    <p>2、可以订阅大师、达人的信息</p>
                    <p>3、可以打赏彩友</p>
                </div>
            </div>
            <div class="question">
                <p>2、如何获得太阳</p>
                <div class="arrowDiv" @click="arrow(1)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>有三种方式可以获得太阳：</p>
                    <p>1、充值</p>
                    <p>太阳充值完全属于自愿原则，可以通过微信、支付宝、银联等第三方支付平台充值获取太阳，1太阳=1元；</p>
                    <p>2、参加活动</p>
                    <p>只要每期都发表预测帖，就自动参加了《每期评优》、《周期奖励》、《月榜奖励》活动，开奖后，会根据发帖的数据自动评优，若被入选，即可自动获得丰厚的太阳奖励(会自动发送系统信息通知您)，若需了解详情，点击<span>了解详情</span></p>
                </div>
            </div>
            <div class="question">
                <p>3、太阳可以兑换现金吗？</p>
                <div class="arrowDiv" @click="arrow(2)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>太阳不可以兑换现金</p>
                </div>
            </div>
            <div class="question">
                <p>4、太阳可以转给好友吗？</p>
                <div class="arrowDiv"  @click="arrow(3)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>“太阳”不能进行转账交易，不能兑换万恒云公司体系外的产品和服务。</p>
                </div>
            </div>
            <div class="question">
                <p>5、太阳可以退货吗？</p>
                <div class="arrowDiv"  @click="arrow(4)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>鉴于好劲抽七星彩提供服务或商品的性质特殊，因此，使用太阳购买的服务或商品属于不宜退货的商品，不适用无理由退货规定。</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
            direction:false
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
    methods:{
        arrow(val){
            this.direction = !this.direction
            let answers = document.getElementsByClassName('answer')
            let arrows = document.getElementsByClassName('arrow')
            for(let i=0;i<answers.length;i++){
                answers[i].style.height = 0;
            }
            answers[val].style.height = 'auto'
            
            for(let i=0;i<arrows.length;i++){
                arrows[i].className = 'arrow';
            }
            arrows[val].className = 'arrow change-direction'
            if(!this.direction){
                answers[val].style.height = 0
                arrows[val].className = 'arrow';
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        min-height: 100%;
        position: absolute;
        background-color: #eee;
        top: 0;
        .content{
            width: 100%;
            background-color: #fff;
            margin: 10px 0;
            p{
                height: 60px;
                line-height: 26px;
                text-indent: 22px;
                margin: 0;
                font-size: 14px;
                padding: 4px 0 0 6px;
                color: #555;
            }
        }
        p{
            font-size: 16px;
            padding-left: 6px;
            font-weight: bolder;
        }
        .questionList{
            width: 100%;
            min-height: 100px;
            background-color: #fff;
            .question{
                position: relative;
                border-bottom: 1px solid #ccc;
                p{
                    font-weight: normal;
                    height: 32px;
                    line-height: 22px;
                    padding: 10px 0 0 6px; 
                    margin: 0;
                }
                .arrowDiv{
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    .arrow{
                        position: absolute;
                        right:8px;
                        top: 10px;
                        width: 8px;
                        height: 8px;
                        border-top: 2px solid #555;
                        border-right: 2px solid #555;
                        transform: rotate(135deg);
                        transform-origin:center;
                        transition: all 0.2s linear;
                    }
                    .change-direction{
                        transform: rotate(-45deg);
                    }
                }
                .answer{
                    background-color: #eee;
                    padding-left: 4px ;
                    color: #555;
                    overflow: hidden;
                    height: 0;
                    p{
                        padding: 0;
                        margin: 0;
                        font-size: 14px;
                        width: 100%;
                        height: auto;              
                    }
                }
            }
        }
        
    }
</style>